import 'assets/styles/app.scss';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();

				// if(document.querySelector('[data-variant]') !== null) {
				// 	let variant = document.querySelector('[data-variant]').dataset.variant;
				// 	let variantComponent = await import(
				// 		/* WebpackMode: "lazy" */
				// 		/* webpackPrefetch: true */
				// 		/* webpackPreload: true */
				// 		`components/${componentName}/${componentName}-${variant}.js`
				// 	)
				// }
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	// Load CSS variables for the variant
	// if(document.querySelector('[data-variant]') !== null) {
	// 	let variant = document.querySelector('[data-variant]').dataset.variant;
	// 	console.log(variant);
	// 	const variantStyle = document.createElement( 'link' );
	// 	variantStyle.href = `dist/styles/variants/variables-${variant}.css`;
	// 	variantStyle.rel = 'stylesheet';
	// 	document.head.appendChild( variantStyle );
	// }
} );
